<template>
  <section>
    <button
      type="button"
      class="btn btn-green"
      :disabled="disabledCreateArticleButton"
      @click="create"
    >
      {{ $t('tasrNews.create_article') }}
    </button>
  </section>
</template>

<script>
import NotifyService from '../../services/NotifyService'
import UserMixin from '../mixins/User'
import TasrNewsCategoryService from '../../services/tasr/TasrNewsCategoryService'
import coreApi from '@/api/core'

const ARTICLE_AUTHOR_NAME = 'TASR'

export default {
  name: 'TasrNewsCreateArticleButton',
  mixins: [UserMixin],
  props: {
    tasrNews: {
      type: Object
    }
  },
  data () {
    return {
      disabledCreateArticleButton: false
    }
  },
  methods: {
    async getAuthorId () {
      try {
        const response = await coreApi().get(`/author?filter_eq[name]=${ARTICLE_AUTHOR_NAME}`)
        if (response?.data?.data?.length > 0) {
          return response.data.data[0].id
        }
      } catch (error) {
        console.error(error)
        NotifyService.setErrorMessage(error)
      }
      return undefined
    },
    async mapTasrNewsToArticle (authorId) {
      let site = null
      let rubric = null
      // Trend.id == 18
      if (this.getUserSite() !== 18 && this.tasrNews.category) {
        const category = TasrNewsCategoryService.getCategoryById(this.tasrNews.category)
        site = (category && category.site) ? category.site : null
        rubric = (category && category.rubric) ? category.rubric : null
      }

      return {
        site: site !== null ? site : this.getUserSite(),
        mainRubric: rubric !== null ? rubric : await this.getUserRubric(),
        field: {
          title: this.tasrNews.headline,
          shortTitle: this.tasrNews.headline,
          subTitle: this.tasrNews.headline,
          bodyText: this.tasrNews.content
        },
        authors: [authorId]
      }
    },
    async create () {
      this.disabledCreateArticleButton = true
      const authorId = await this.getAuthorId()
      if (!authorId) {
        this.disabledCreateArticleButton = false
        return
      }
      const payload = await this.mapTasrNewsToArticle(authorId)
      payload.tasrId = this.tasrNews.id
      this.$store.dispatch('article/createFromTasr', payload)
        .then(() => {
          this.disabledCreateQuizArticleButton = false
          if (this.$store.getters['article/error']) {
            NotifyService.setErrorMessage(this.$store.getters['article/error'])
            return
          }
          const newArticle = this.$store.getters['article/fullArticle']
          NotifyService.setSuccessMessage(this.$t('tasrNews.article_created'))
          if (newArticle.id > 0) {
            this.$emit('set-article-and-save', newArticle)
            this.$router.push('/article/' + newArticle.id + '/edit')
          }
        })
        .catch(error => {
          this.disabledCreateQuizArticleButton = false
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">

</style>
